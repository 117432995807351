import React from "react";
import spotlight from "../database/spotlight.json" 
import ListingLatest from "../database/ListingLatest.json" 
class Home extends React.Component {
  constructor(props){
    super(props)
    spotlight.ListingLatest = ListingLatest
    this.state = spotlight
  }
  cryptocurrency(key,title){
    let data = this.state[key]
    return <div key={key} className={key+" d-inline"}>
              <div className="list_cryptocurrency d-margin-2 d-padding-1">
                <div className="title d-padding-1">{title}</div>
                <div className="list d-padding-1">
                  {this.list_cryptocurrency(data)}
                </div>
              </div>
            </div>
  }
  list_cryptocurrency(data){
    let crypto = data.slice(0,3)
    return crypto.map((item,index)=>
      <div key={item.id} className="item-list d-grid d-margin-t-1">
          <div className="item-title col-8"><span className="number">{++index}</span> <img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/"+item.id+".png"} alt="" /> {item.name} <span className="symbol">{item.symbol}</span></div>
          <div className="item-price col-2 d-text-align-right" data-change={parseFloat(item.priceChange.priceChange24h)>0?"up":"down"}>{Math.abs(  parseFloat(item.priceChange.priceChange24h).toFixed(2) )+"%"}</div>
      </div>
      )
  }
  list_coin_table(){
   let coins = this.state.ListingLatest.cryptoCurrencyList
   let start = 1
   let cur = 2
   let curency = "$"
   let percent = "%"
   return coins.map( (item,index)=>
    <tr key={index} data-change24h={parseFloat(item.quotes[cur].percentChange24h)>0?"up":"down"}>
        <td className="d-text-align-center">{index+start}</td>
        <td>
          <div className="item-title">
            <img src={"https://s2.coinmarketcap.com/static/img/coins/64x64/"+item.id+".png"} alt="" /> 
            <div className="coin_name">
              <strong>{item.name}</strong> <span className="symbol">{item.symbol}</span>
            </div>
          </div>
        </td>
        <td><div className="item-price">{curency+this.format_currency(item.quotes[cur].price)}</div></td>
        <td><div className="item-change 24h d-text-align-center">{Math.abs( parseFloat(item.quotes[cur].percentChange24h).toFixed(2) )+percent}</div></td>
        <td><div className="item-price market-cap d-text-align-center" >{curency+this.format_currency(item.quotes[cur].marketCap)}</div></td>
        <td><div className="item-price volume24h d-text-align-center" >{curency+this.format_currency(item.quotes[cur].volume24h)}</div></td>
        <td><div className="item-price-graph d-text-align-center"><img src={item.sparklines} alt="" /></div></td>
        <td className="d-text-align-center">
          <button className="btn-primary">Buy</button>
        </td>
    </tr> 
   )
  }
  format_currency(money){
    let step = 2
    let max = 6
    let expert = 1000000
    let dec = money.toString().split('.')
    if(!dec[1])return money
    if(dec[1].length > 2) step = dec[1].length
    if(dec[1].length > max) step = max
    if(money > expert)return this.expert_currency(money)
    return Math.abs( parseFloat(money).toFixed(step) )
  }

  expert_currency(money){
    let resulf = ""
    let B = 1000000000
    let M = 1000000
    if(money > M){
      resulf = Math.abs( parseFloat(money/1000000).toFixed(2) ) + "M"
    }
    if(money > B){
      resulf = Math.abs( parseFloat(money/1000000000).toFixed(2) ) + "B"
    }
    return resulf
  }

  render(){
    return (
      <div className="home-template">
        <div className="banner-home-page">
          <div className="container d-text-align-center d-padding-8">
            <h2 className="d-padding-tb-2">Disrupting Education</h2>
            <div className="col-4 d-inline text-justify content d-margin-tb-4">
                <div className="d-padding-2">
                Larecoin is an upgradeable P2P tuition token used for education. Inclusive to it's own token and digital asset exchange, larecoin is used on a three layer technology engineered for students (lareX), educational systems (lareVIA) and business (lareR) use cases. A first of its kind block chain technology fueling the future of global education.
                </div>
              </div>
          </div>
        </div>
        <div className="Cryptocurrency d-padding-8">
            <div className="container">
              <h3>Today's Cryptocurrency Prices by Larecoin</h3>
              <div className="d-grid-1-3 d-margin-t-2">
                  {this.cryptocurrency("trendingList","Trending")}
                  {this.cryptocurrency("gainerList","Biggest Gainers")}
                  {this.cryptocurrency("recentlyAddedList","Recently Added")}
              </div>
            </div>
        </div>
        <div className="ListingMarketCap">
            <div className="container">
              <div className="d-scroll">
                <table className="list-coins-table">
                  <thead>
                    <tr>
                        <th>#</th>
                        <th className="d-text-align-left">Name</th>
                        <th>Price</th>
                        <th>24h%</th>
                        <th>Market Cap</th>
                        <th>Volume(24h)</th>
                        <th>Last 7 Days</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.list_coin_table()}
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>
    );
  }
  }
  export default Home