import React from 'react';
import logo from '../assets/images/logo_larecoin.png'
class Header extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            menus: this.props.data_config.menu??[] ,
            mobile: false
        }
    }
    hamburger(){
        this.setState({
            mobile: !this.state.mobile
        })
        // event.classList.toggle('open')
        // let menu = document.querySelector('.menu-main')
        // let hamburger = document.querySelector('.hamburger .login')
        // menu.classList.toggle('mobile')
        // menu.classList.toggle('animate__animated')
        // menu.classList.toggle('animate__fadeInRight')
        // menu.classList.toggle('animate__faster')
        // hamburger.classList.toggle('active')
        // document.querySelector('.header .hamburger').classList.toggle('open')
    }
    render(){
        const show_menus = this.state.menus.map( item=><li key={item.id}><a href={item.slug} className="d-padding-2 d-margin-2">{item.title}</a></li>)
        return (
            <header className="App-header d-padding-tb-1">
            <div className="container-fullwidth">
                <div className="d-grid clear-both">
                    <div className="logo col-2">
                            <a href="/">
                                <img src={logo} alt=""/>
                            </a>
                    </div>
                    <div className={this.state.mobile?'menu-main mobile animate__animated animate__fadeInRight animate__faster':'menu-main col-6 d-text-align-center hide-sm'}>
                        <ul className="d-grid-inline">
                        {show_menus}
                        </ul>
                    </div>   
                    <div className="user col-2 d-inline d-float-right hide-sm">
                        <button className="btn-info">Login</button>
                        <button className="btn-primary">Resgister</button>
                    </div> 
                    <div className={this.state.mobile?'hamburger col-8 d-text-align-right open':'hamburger col-8 d-text-align-right'}>
                        <button className="btn-primary">Login</button>
                        <div className={this.state.mobile?'d-inline open':'d-inline'} id="nav-menu-mobile" onClick={()=>this.hamburger()}><span></span><span></span><span></span></div>
                    </div>
                </div>     
            </div>
          </header>
        )
    }
}
export default Header