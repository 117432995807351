const renderFooter = (props)=>{
    const widgets = props.data_config.footer??{}
    const show_list = (data) => data.map( item=><li key={item.id}><a href={item.id}>{item.title}</a></li>)
    const show_widget = Object.keys(widgets).map(widget=> 
        <div key={widget} className={widget+" widget_footer"}>
                    <h3>{widgets[widget].title}</h3>
                    <ul>
                        {show_list(widgets[widget].list)}
                    </ul>
                </div>
        )
    return (
        <div className="footer d-padding-tb-4">
            <div className="container-fullwidth">
                <div className="d-grid-inline d-grid-1-3 d-padding-2">
                    {show_widget}
                </div>
                <div className="copy-right d-text-align-center">COPYRIGHT © 2018 LARECOIN LIMITED. ALL RIGHTS RESERVED.</div>
            </div>
        </div>
    )
} 

export default renderFooter