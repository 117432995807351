import * as React from "react";
import {  BrowserRouter ,Routes, Route } from "react-router-dom";
// Pages
 import HomePage from "./pages/home";
 import NoPageFound from "./pages/nopagefound";

function RouteMain() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} exact /*strict*/ element={<HomePage/>}/>
                    <Route path='*' exact={true} element={<NoPageFound/>}/>
                </Routes>
            </BrowserRouter> 
        );
}

export default RouteMain;