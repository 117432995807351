import React from 'react';
import notfound from "../assets/images/404_animation.gif"
function NoPageFound()  {
        return (
            <div className="container d-text-align-center">
                <img src={notfound} alt=""/>
            </div>
        );
}

export default NoPageFound;

