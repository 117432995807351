import './assets/css/responsive.css';
import './assets/css/animate.min.css';
import './App.css';
import Header from './layouts/header'
import Footer from './layouts/footer'
import config from './config/larecoin.json'
import Routes from './routes'
function App() {
  return (
    <div className="App">
      <Header data_config={config}/>
      <Routes />
      <Footer data_config={config} />
    </div>
  );
}

export default App;
